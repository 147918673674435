import  React from 'react'
import {useEffect, useState} from 'react';
import logo from "../assets/images/logo.webp";
import { Icon } from '@iconify/react';

import styles from '../assets/css/navbar.module.css';

const NavScrollExample = () => {
  const [scroll, setScroll] = useState(false);
  const [openSubNav, setOpenSubNav] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }
  , [scroll]);

  const handleOpenSubNav = () => {
    setOpenSubNav(!openSubNav);
  }


  const openMenuClasses  = [styles.openMenu, openSubNav ? styles.anima : ''].join(' ');
  const navLinksClasses  = [styles.NavLinks, openSubNav ? styles.showNavLinks : ''].join(' ');
  const NavClasses  = [styles.mainNav, scroll ? styles.fixedMenu : ''].join(' ');

  return <header className={styles.pageHeader}>
    <div className={styles.minNav}>
      <div className={styles.contacts}>
        <div className={styles.email}>
          <Icon icon="ph:phone-bold" />
          <span> +256 758 280889</span>
        </div>
        <div className="phone">
          <Icon icon="octicon:mail-16" />
          <span> info@joyofchristuganda.org</span>
        </div>
      </div>
    </div>
    <div className={NavClasses}>
      <a href='https://joyofchristuganda.org/' className={styles.logo}>
        <img src={logo} alt="headerlogo" />
        <span>Joy of Christ Uganda</span>
      </a>
      <div onClick={handleOpenSubNav} className={openMenuClasses}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul id="NavLinks" className={navLinksClasses}>
        <li>
          <a href="https://joyofchristuganda.org/">Home</a>
        </li>
        <li>
          <a href="https://joyofchristuganda.org/aboutus.html">About us</a>
        </li>
        <li>
          <a href="https://joyofchristuganda.org/church_ministry.html">Church </a>
        </li>
        <li>
          <a  href="https://joyofchristuganda.org/children_ministry.html"
            >Children </a
          >
        </li>
        <li>
          <a className={styles.activeLink} href="/">sponsor a Child</a>
        </li>
        <li>
          <a href="https://joyofchristuganda.org/contact_us.html">Contact us</a>
        </li>
      </ul>
      <div className={styles.donate}>
        <a href="https://joyofchristuganda.org/donate.html" className={styles.mainButton}>Donate now</a>
      </div>
     </div>
    </header>
  
}

export default NavScrollExample