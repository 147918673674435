import React, { useState} from 'react'
import { Card, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import styles from '../assets/css/childCard.module.css'
import { calculateAge, checkBirthday } from '../Engines/DateEngine'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook } from '@fortawesome/free-solid-svg-icons'

export default function ChildCard({
  child,
  openChildStoryHandler,
  handleOpenDeletemodal,
  currUser,
}) {
  const [sponsorStatus, setSponsorStatus] = useState(child.sponsored)
  // useEffect(() => {
  //   const updateSponsorStatus = async () => {
  //     const docRef = doc(db, "children", child.id);
  //     try {
  //       await updateDoc(docRef, {
  //         ...child,
  //         sponsored: sponsorStatus,
  //       });
  //     } catch (error) {
  //       console.log("sponsor status failed to update", error);
  //     }
  //   };
  //   updateSponsorStatus();
  // }, [sponsorStatus]);

  const childCardColors = sponsorStatus
    ? [styles.childCard, styles.childCardSponsored]
    : styles.childCard

  return (
    <Card className={childCardColors}>
      <Card.Img
        variant="top"
        src={child.childPhoto}
        className={styles.childCardImage}
      />
      <Card.Body>
        <Card.Title className={styles.childCardTitle}>
          {child.firstName}
        </Card.Title>
        <Card.Text>
          {checkBirthday(child.birthDay)
            ? `${child.birthDay}`
            : `${calculateAge(child.birthDay)} Years old`}
        </Card.Text>
        <Button
          className={styles.childCardStory}
          onClick={() => openChildStoryHandler(child._id)}
        >
          <FontAwesomeIcon icon={faBook} className={styles.storyIcon} />
          {child.firstName}'s story
        </Button>
        {!child.sponsored ? (
          <Link
            className={styles.childCardButton}
            variant="primary"
            to={`/sponsorachild/${child._id}`}
          >
            Sponsor Now
          </Link>
        ) : (
          <button className={styles.childCardButton} disabled>
            Sponsored
          </button>
        )}
        {currUser && (
          <>
            <label className={styles.sponsorStatus}>
              <input
                type="checkbox"
                checked={sponsorStatus}
                onClick={() => setSponsorStatus(!sponsorStatus)}
              />
              Already sponsored
            </label>
            <Link
              className={styles.editAChildButton}
              to={`/sponsorachild/${child._id}/edit`}
            >
              Edit Child
            </Link>

            <button
              onClick={() => handleOpenDeletemodal(child)}
              className={styles.deleteAChildButton}
            >
              Remove Child
            </button>
          </>
        )}
      </Card.Body>
    </Card>
  )
}
